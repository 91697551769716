<template>
    <div class="myClass">
    <section class='trbaner'>
        <div class="foxj">
            <div class='am-container'>
                <div class='txt'>
                    <div class="am-vertical-align-middle">
                     <p class="tit">服务支持</p>
                     <p class="note">为用户提供多种技术培训与技术支持，帮助用户正确地选择和使用产品，及时为用户排除使用中发生的故障。</p>
                </div>
                </div>
            </div>
        </div>
        <img src='../assets/banner/banner4.png'>
    </section>
    <section class="dowm-fi">
        <div class="am-container">
            <ul class="am-avg-sm-3">
                <li>
                    <a href='/views/service-1'>
                        <i><img src="~images/icon_do1.png"></i>
                        <div class="txt">
                            <p class="tit" >技术文档</p>
                            <p class="note">产品使用手册下载</p>
                        </div>
                    </a>
                </li>
                <li>
                    <a href='/views/service-2'>
                        <i><img src="~images/icon_do2.png"></i>
                        <div class="txt">
                            <p class="tit">软件下载</p>
                            <p class="note">产品测试工具下载</p>
                        </div>
                    </a>
                </li>
                <li>
                    <a @click="dialogVisible = true" href="javascript:;">
                        <i><img src="~images/icon_do3.png"></i>
                        <div class="txt" >
                            <p class="tit">申请试用</p>
                            <p class="note">产品试用申请要求</p>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </section>
    <section class="nybox">
        <div class="am-container">
            <div class="ue-title">
                <p class="tit">软件下载</p>
            </div>
            <div class="down-list">
                <ul class="am-avg-md-2">
                    <li v-for="(item,index) in dataList1" :key='index'>
                        <a href="javascript:">
                            <i><img src="~images/软件-10 (1).png"></i>
                            <div class="txt">
                                  <p class="tit">{{item.soft_name}}</p>

                                <p class="note">{{item.soft_blurb}}</p>
                            <span class="btn" @click="download(item)">下载 →</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <section>
        <div class="block">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="8"
                layout=" prev, pager, next"
                :total="this.total">
                </el-pagination>
            </div>
        </section>
    </section>
    <el-dialog
    title="申请试用"
    :visible.sync="dialogVisible"
    width="30%">
    <input type="text" placeholder="姓名"  >
    <input type="text" placeholder="电话"  >
    <textarea  placeholder="其他要求" style="width = 95% , height = 140px" ></textarea>
    <span slot="footer" class="dialog-footer" >
        <el-button type="primary" @click="dialogVisible = false" style="width = 100%">提交</el-button>
    </span>
    </el-dialog>
    <!-- <section class="am-pd">
        <div class='am-container'>
            <div class="ue-title">
                <p class="tit">联系支持</p>
            </div>
            <div class="contact-information">
                <ul>
                    <li>
                        <div class="am-cf">
                            <i><img src="~images/icon_t1.png"></i>
                            <p class="tit">技术支持电话</p>
                            <p class="note">{{this.$parent.data.company_tel}}</p>
                        </div>
                    </li>
                    <li>
                        <div class="am-cf">
                            <i><img src="~images/icon_t2.png"></i>
                            <p class="tit">技术支持信息</p>
                            <p class="note">{{this.$parent.data.company_email}}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section> -->
    </div>
</template>
<script>
import {
    getList1
} from "api/service";
export default {
    data () {
        return {
            dialogVisible: false,
            //软件列表
            dataList1:[],
            searchInfo: {
            page_no: 1,
            page_size: 8,
        },
            total:[]

        }
    },
    methods: {
     handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.searchInfo.page_no = val
        this.getList1()

      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.searchInfo.page_no = val
        this.getList1()
      },
        //获取软件工具
        getList1() {
        this.isShowloading = true;
      console.log(this.searchInfo);
      getList1(this.searchInfo).then((res) => {
        console.log(res);
        this.dataList1 = res.data.list;
        this.total = res.data.entity.all_count
        console.log(this.total)
        this.isShowloading = false;
        console.log(this.modelForm)
      });
    },
    //下载文件
    download(item){
        
      let fuid = '/dqcsys/mweb/uploadFile/download?fu_no=' + item.fu_no
      window.open(fuid)
    },
    },
    mounted() {
        this.getList1()     
    }
    
}
</script>
<style lang="scss" scoped>
.trbaner {
    position: relative;
}
.trbaner img {
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    border: 0;
}
.trbaner .foxj {
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 22;
}
.am-container {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1230px;
}
.foxj .txt {
    position: absolute;
    text-align: left;
    left: 1.5rem;
    height: 100%;
    display: table-cell;
    width: 46%;
}
.foxj .txt .tit{
    font-size: 32px;
    margin-bottom: 15px;
    color: #fff;
}
.foxj .note{
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 2;
    color: #fff;
}
.am-container::after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.foxj .txt .am-vertical-align-middle {
       position: relative;
       top: 30%;
}
.dowm-fi {
    padding: 18px
}
.am-avg-sm-3 li .tit {
    font-size: 18px;
    color: #333;
}
.am-avg-sm-3 li .note {
    color: #999;
    font-size: 12px;
}
.am-avg-sm-3 li i {
    display: inline-block;
    position: relative;
    top: -12px;
    vertical-align: middle;
}
.am-avg-sm-3 li .txt {
    display: inline-block;
    text-align: left;
}
.am-avg-sm-3 {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
}
.am-avg-sm-3 li {
    display: inline;
    float: left;
    width: 30%;
}
.am-avg-sm-3 li i{
    display: inline-block;
    position: relative;
    top: -12px;
}
.nybox {
    background-color: #f5f5f5;
    padding:30px 0
}
.nybox .ue-title {
    text-align: center;
    .tit {
        font-size: 32px;
    }
}
.down-list {
    margin-top:15px;
    li {
        padding: 15px 25px;
        display: inline;
        float: left;
        width: 45%;
        // display: block;
        height: auto;
    }
}
.am-avg-md-2::before {
    content: '';
    display: table;
}
.am-avg-md-2 li a {
    background: #fff;
    display: block;
    padding: 20px;
}
.am-avg-md-2 .txt {
    text-align: left;
    display: inline-block;
    width: 80%;
    .tit {
    font-size: 18px;
    color: #333;
    float: left;
    width: 100%;
}
    .note {
    font-size: 12px;
    color: #999;

}
}
.am-avg-md-2 .btn {
    float: right;
    margin-right: 10%;
    display: inline-block;
    background: #4da9ff;
    color: #fff;
    padding: 4px 20px;
    border-radius: 20px;
    font-size: 14px;
}
.am-avg-md-2 li i {
    display: inline-block;
    position: relative;
    // top: -12px;
    vertical-align: middle;
    float: left;
}
.am-pd {
    padding: 80px 0;
    .ue-title {
        font-size: 32px;
    }
    .contact-information {
    max-width: 1000px;
    margin: 30px auto 0 auto;
    }
    ul{

    li {
        display: inline;
        float: left;
        width: 45%;
        list-style: none;
        margin: 0 25px;
        .am-cf {
                padding:25px 60px;
                border:1px solid #eee;
        .note {
         font-size: 22px;
        color: #4da9ff;
        }
        i {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            float: left;
        }

    }
    }
    }


}

</style>
<style lang="scss" >
.el-dialog {
    padding: 20px;
    border-radius: 10px;;
    .el-dialog_body {
        padding: 20px;
    }
    .dialog-footer {
        width: 100%;
    }
    input {
        background:#f5f5f5;
        padding:12px;
        margin-bottom: 15px;
        width: 95%;
        border: 0px solid #fff ;
    }
    textarea {
        background: #f5f5f5;
        padding: 12px;
        width: 95%;
        height: 140px;
    }
}
.el-pagination {
    white-space: normal;
}
</style>